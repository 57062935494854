//$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";


$primary: #039e59;
$secondary: #eee;
//** Global text color on `<body>`.
$text-color: #777;


//** Global textual link color.
$link-color: $primary;

$green-vivetix: $link-color;


//== Typography

//== Typography
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: Montserrat, sans-serif;
$font-size-base: 0.9rem;
// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$h1-font-size: $font-size-base * 3.5;
$badge-font-size: 0.85em;

$table-border-color: #ccc;

$body-color: #777777;

// breakpoint
$x-small: 360px;
$small-sm: 576px;
$medium-md: 768px;
$large-lg: 991px;
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
